import { FC } from 'react';
import * as S from './styles';
import { Row, Col } from 'antd';
import Button from '../Button';
import { H3, Subtitle } from '../Typography';
import ROUTES from '../../utils/routes';

type Props = {
  statusCode?: number;
};

const Error: FC<Props> = ({ statusCode = 500 }) => (
  <S.Wrapper>
    <Row justify="center">
      <Col>
        <S.BulbWrapper>
          <S.BulbImage src="/img/error-bulb.svg" alt="" />
          <S.BulbText>{statusCode}</S.BulbText>
        </S.BulbWrapper>
      </Col>
    </Row>
    <H3 tag="div" marginTop={32} marginBottom={8}>
      Something went wrong
    </H3>
    <Subtitle tag="div" fontWeight="normal" marginBottom={32}>
      Well, this is unexpected. Let's get you out of here.
    </Subtitle>
    <div>
      <Button href={ROUTES.index}>Take me home</Button>
    </div>
  </S.Wrapper>
);

export default Error;
