export default {
  index: '/',
  lesson: '/lesson',
  resourceGroup: '/resource-group',
  signIn: '/sign-in',
  subject: '/subject',
  tags: '/tags',
  tagCategories: '/tag-category',
  testBook: '/test-book',
  users: '/users',
  subjectOrder: '/subject-order',
  testOrder: '/test-order',
  faqs: '/digital-sat-faqs',
  feedback: '/digital-sat-feedback',
  practiceSet: '/practiceSet',
  questionBank: '/question-bank',
  tutor: '/tutor',
  tutorClasses: '/tutor/classes',
  tutorTutors: '/tutor/tutors',
  tutorStudents: '/tutor/students',
  tutorHomework: '/tutor/homework',
};
