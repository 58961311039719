import styled from 'styled-components';
import { Menu as AntMenu } from 'antd';

export const LogoWrapper = styled.a`
  display: block;
  padding: 0 20px;
`;

export const Logo = styled.img`
  width: 100%;
  margin-bottom: 25px;
`;

export const Menu = styled(AntMenu)`
  border: 0;
  user-select: none;

  .ant-menu-submenu-title,
  .ant-menu-item {
    font-size: 16px;
  }
`;

export const MenuLink = styled.a`
  && {
    &,
    &:hover {
      color: currentColor;
      transition: inherit;
    }
  }
`;
