import { FC } from 'react';
import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';

const StagingHeader = styled.div`
  z-index: 20;
  position: sticky;
  display: flex;
  top: 0;
  width: 100%;
  padding: 10px 25px;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.supernova};
  font-size: 18px;
  user-select: none;

  strong {
    display: inline;
  }

  p {
    margin: 0;
  }
`;

const Header: FC = () => (
  <StagingHeader>
    <WarningOutlined />
    <p>
      WARNING! This is the <strong>{process.env.CUSTOM_ENV}</strong> environment.
    </p>
    <WarningOutlined />
  </StagingHeader>
);

export default Header;
