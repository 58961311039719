import { useApolloClient } from '@apollo/client';
import { MeDocument, User, UserRoleEnum } from '@codegen';
import * as Sentry from '@sentry/react';
import { message } from 'antd';
import React, {
  createContext, Dispatch,
  SetStateAction,
  useCallback, useEffect, useState
} from 'react';
import { logoutAction } from '../utils/helpers';
import { auth } from './firebase';

export type UserType = Partial<User>;
export type SetUserType = Dispatch<SetStateAction<UserType | null>>;

const initialUserLoadingState = true;

export const UserContext = createContext<{
  user: UserType | null;
  setUser: SetUserType | null;
  userLoading: boolean;
}>({
  user: null,
  setUser: null,
  userLoading: initialUserLoadingState,
});

const FirebaseUserProvider: React.FC = ({ children }) => {
  const client = useApolloClient();
  const [user, setUser] = useState<UserType | null>(null);
  const [userLoading, setUserLoading] = useState(initialUserLoadingState);

  const resetState = useCallback(() => {
    setUser(null);
    setUserLoading(false);
    Sentry.configureScope(scope => scope.setUser(null));
    client.resetStore();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async userState => {
      if (!userState) return resetState();
      setUserLoading(true);
      try {
        const { data } = await client.query({
          fetchPolicy: 'network-only',
          query: MeDocument,
        });
        const dbUser: UserType | null = data.user;
        if (!dbUser || dbUser.role === UserRoleEnum.Subscriber) {
          message.error('You do not have the necessary permissions to log in');
          logoutAction();
          return resetState();
        }
        Sentry.configureScope(scope => {
          scope.setUser({
            id: data?.user?.id,
            email: data?.user?.email,
            username: `${data?.user?.firstName} ${data?.user?.lastName}`,
          });
        });
        setUser(dbUser);
      } catch (err) {
        logoutAction();
        message.error(err.message);
        return resetState();
      }
      setUserLoading(false);
      return {};
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, userLoading }}>{children}</UserContext.Provider>
  );
};
export default FirebaseUserProvider;
