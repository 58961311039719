import React, { Component, ComponentType, createElement } from 'react';
import * as Sentry from '@sentry/react';

import AppError, { ErrorInfo, FallbackComponentProps } from './Containers/AppError';

interface ErrorBoundaryProps {
  fallbackComponent: ComponentType<FallbackComponentProps>;
  onError: (error: Error, info: ErrorInfo) => void;
}

interface ErrorBoundaryState {
  error: Error | null;
  info: ErrorInfo | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps: ErrorBoundaryProps = {
    onError: () => undefined,
    fallbackComponent: AppError,
  };

  state: ErrorBoundaryState = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      error,
      info,
    });

    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });

    this.props.onError(error, info);
  }

  render() {
    const { error, info } = this.state;

    if (error && info) {
      return createElement(this.props.fallbackComponent, { error, info });
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

export const withErrorBoundary = (WrappedComponent: any) => (props: any) => (
  <ErrorBoundary>
    <WrappedComponent {...props} />
  </ErrorBoundary>
);
