import { FC, useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import * as S from './styles';
import AnimationData from '../../public/img/loader.json';

export interface Props {
  center?: boolean | 'block';
  size?: 'default' | 'large';
}

const Loader: FC<Props> = ({ center, size = 'default' }) => {
  const loaderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (loaderRef.current) {
      lottie.loadAnimation({
        container: loaderRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: AnimationData,
      });
    }
  }, [loaderRef]);

  return <S.Loader ref={loaderRef} center={center} size={size} />;
};

export default Loader;
