import { FC, useContext, ReactElement } from 'react';
import Link from 'next/link';
import { Layout, Menu, Row, Col, Select } from 'antd';
import {
  FormOutlined,
  VideoCameraOutlined,
  UserOutlined,
  LogoutOutlined,
  TagsOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { AppContext } from '../../lib/appContext';
import { logoutAction } from '../../utils/helpers';
import * as S from './styles';
import routes from '../../utils/routes';
import { useRouter } from 'next/router';
import { sortBy } from 'lodash';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;

type Param = { key: string; value: string | number };

type MenuItem = {
  key: string;
  route?: keyof typeof routes;
  params?: Param[];
  icon?: ReactElement;
  title: string;
  children?: MenuItem[];
};

enum MenuKeys {
  tags = 'tags',
  tagCategories = 'tag-category',
  tests = 'tests',
  users = 'users',
  videoContent = 'videoContent',
  subjectOrder = 'subject-order',
  testOrder = 'test-order',
  faqs = 'faqs',
  feedback = 'feedback',
  questionBank = 'question-bank',
  tutor = 'tutor',
}

const getRoute = (route: keyof typeof routes, params?: Param[]) => {
  const hrefQuery = params?.map(({ key }) => `/[${key}]`) ?? '';
  const asQuery = params?.map(({ value }) => `/${value}`) ?? '';
  const routeValue = routes[route];

  const href = routeValue + hrefQuery;
  const as = routeValue + asQuery;

  return { as, href };
};

const renderMenuItemLink = ({ route, params = [], title }: MenuItem) => {
  if (!route) return title;

  const { as, href } = getRoute(route, params);

  return (
    <Link href={href} as={as} passHref>
      <S.MenuLink>{title}</S.MenuLink>
    </Link>
  );
};

const renderMenuItem = (item: MenuItem) => {
  const key = item.route ? getRoute(item.route, item.params).as : item.key;

  return item.children ? (
    <SubMenu key={key} title={item.title} icon={item.icon}>
      {item.children.map(child => renderMenuItem(child))}
    </SubMenu>
  ) : (
    <Menu.Item key={key} icon={item.icon}>
      {renderMenuItemLink(item)}
    </Menu.Item>
  );
};

const AppLayout: FC = ({ children }) => {
  const { activeCourse, setActiveCourse, setActiveCourseId, courses } = useContext(AppContext);
  const { push } = useRouter();

  const onCourseChange = (value: string) => {
    if (setActiveCourseId && value !== activeCourse?.id) {
      setActiveCourseId(value);

      const newActiveCourse = courses.find(course => course.id === value);
      if (setActiveCourse && newActiveCourse) {
        setActiveCourse(newActiveCourse);
      }
      push(routes.index);
    }
  };

  const { asPath } = useRouter();
  let subjectsArr: any = activeCourse?.subjects;
  if (activeCourse?.id === '3') {
    let newArr = [];
    for (let x in subjectsArr) {
      newArr.push(subjectsArr[x]);
    }
    subjectsArr = newArr;
  }
  const digitalSatMenuItem: MenuItem[] = [
    {
      key: MenuKeys.tagCategories,
      route: 'tagCategories',
      icon: <TagsOutlined />,
      title: 'Tag Categories',
    },
    {
      key: MenuKeys.tutor,
      icon: <MessageOutlined />,
      title: 'Tutor Management',
      children: [
        { title: 'Classes', route: 'tutorClasses' },
        { title: 'Tutors', route: 'tutorTutors' },
        { title: 'Students', route: 'tutorStudents' },
        { title: 'Homework', route: 'tutorHomework' },
      ].map((data: any, index: any) => ({
        title: data.title,
        key: `tutor-${index}`,
        route: data.route,
      })),
    },
    {
      key: MenuKeys.faqs,
      route: 'faqs',
      icon: <QuestionCircleOutlined />,
      title: 'Digital SAT FAQs',
    },
    {
      key: MenuKeys.feedback,
      route: 'feedback',
      icon: <QuestionCircleOutlined />,
      title: 'Digital SAT Feedback',
    },
    {
      key: MenuKeys.questionBank,
      route: 'questionBank',
      icon: <QuestionCircleOutlined />,
      title: 'Question Bank',
    },
  ];

  const menuItems: MenuItem[] = [
    {
      key: MenuKeys.videoContent,
      icon: <VideoCameraOutlined />,
      title: 'Video Content',
      children: sortBy(subjectsArr, subject => subject.order)?.map(({ title, id }) => ({
        title:
          title === 'Section 1'
            ? 'Reading and Writing'
            : title === 'Section 2 Easy'
              ? 'Math'
              : title === 'Section 2 Hard'
                ? 'General'
                : title,
        key: `vc-${id}`,
        route: 'subject',
        params: [{ key: 'sid', value: id }],
      })),
    },
    {
      key: MenuKeys.subjectOrder,
      route: 'subjectOrder',
      icon: <SettingOutlined />,
      title: 'Subject order',
    },
    {
      key: MenuKeys.testOrder,
      route: 'testOrder',
      icon: <SettingOutlined />,
      title: 'Bluebook test order',
    },
    {
      key: MenuKeys.tags,
      route: 'tags',
      icon: <TagsOutlined />,
      title: 'Tags',
    },
    {
      key: MenuKeys.tests,
      icon: <FormOutlined />,
      title: activeCourse?.id === '3' ? 'Test' : 'Test Books',
      children: activeCourse?.testBooks?.map(({ title, id }) => ({
        title,
        key: `testBook-${id}`,
        route: 'testBook',
        params: [{ key: 'bid', value: id }],
      })),
    },
    {
      key: MenuKeys.users,
      route: 'users',
      icon: <UserOutlined />,
      title: 'Users',
    },
  ];
  const newMenuItem: MenuItem[] =
    activeCourse?.id === '3' ? [...menuItems, ...digitalSatMenuItem] : menuItems;
  return (
    <Layout style={{ height: '100vh' }}>
      <Sider theme="dark" style={{ padding: '20px 0' }} width={300}>
        <Link href={routes.index} passHref>
          <S.LogoWrapper>
            <S.Logo src="/img/logo-white.svg" alt="SupertutorTV" />
          </S.LogoWrapper>
        </Link>
        <S.Menu
          mode="inline"
          // @ts-ignore: styled-components & antd prop conflict
          theme="dark"
          defaultOpenKeys={[MenuKeys.tests, MenuKeys.videoContent]}
          selectedKeys={[asPath]}
          inlineIndent={16}
        >
          {newMenuItem.map(item => renderMenuItem(item))}
        </S.Menu>
      </Sider>
      <Layout>
        <Header style={{ backgroundColor: '#052b4e', padding: '5px 25px', height: 'auto' }}>
          <Row justify="space-between" align="middle">
            <Col>
              {activeCourse && courses.length && (
                <Select
                  defaultValue={activeCourse.id}
                  onChange={onCourseChange}
                  style={{ width: 200 }}
                >
                  {courses.map(({ title, id }) => (
                    <Option key={id} value={id}>
                      {title}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col style={{ fontSize: 22, color: '#ffffff' }}>
              <LogoutOutlined onClick={logoutAction} title="Logout" />
            </Col>
          </Row>
        </Header>
        <Content style={{ background: '#ffffff' }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
