import { SyntheticEvent } from 'react';
import { auth } from '../lib/firebase';
import moment from 'moment';
import { Select } from 'antd';
import { ParsedUrlQuery } from 'querystring';
import { omit } from 'lodash';

export const logoutAction = async (e?: SyntheticEvent) => {
  if (e) {
    e.preventDefault();
  }
  await auth.signOut();
};

export const timestampToDatetimeString = (timestamp: number | string) => {
  const timestampInt = typeof timestamp === 'number' ? timestamp : parseInt(timestamp, 10);
  return new Date(timestampInt).toLocaleDateString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

type ValueOf<T> = T[keyof T];

export const parseQueryParam = (param: ValueOf<ParsedUrlQuery>) =>
  (param != null && (typeof param === 'string' ? param : param[0])) || '';

export const humanizeDate = (date: Date) => moment(date).format('MMMM Do,  YYYY, h:mm a');

export const snakeCaseToSpaces = (input: string) => input.replace('_', ' ');

export const filterByChildrenToStartWith: Select['props']['filterOption'] = (value, option) =>
  option?.children
    ? option.children.toString().toLowerCase().startsWith(value.toLowerCase())
    : false;

export const filterByChildrenToInclude: Select['props']['filterOption'] = (value, option) =>
  option?.children ? option.children.toString().toLowerCase().includes(value.toLowerCase()) : false;

export const isDevelopment = process.env.CUSTOM_ENV === 'development';
export const isStaging = process.env.CUSTOM_ENV === 'staging';
export const isProduction = process.env.CUSTOM_ENV === 'production';

export const downloadCSV = async ({
  dataArray,
  headers,
  fileName,
}: {
  dataArray: any[];
  headers: string;
  fileName: string;
}) => {
  const dataArrays = dataArray?.map(dataRow => {
    const arrayWithoutTypename = omit(dataRow, '__typename');
    return Object.values(arrayWithoutTypename).join(',');
  });

  const csvContent = [`data:text/csv;charset=utf-8,${headers}`].concat(dataArrays).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
};

/**
 * Move an element in array. In case of the invalid index
 * range returns a given array without modification.
 *
 * @param fromIndex
 * @param toIndex
 * @param samples
 */

export const moveInArray = <T>(fromIndex: number, toIndex: number, samples: T[]): T[] => {
  if (samples.length === 0) {
    return samples;
  }

  if (fromIndex < 0 || toIndex >= samples.length) {
    return samples;
  }

  const fromSample = samples[fromIndex];
  const remainingSamples = [...samples];

  remainingSamples.splice(fromIndex, 1);

  return [...remainingSamples.slice(0, toIndex), fromSample, ...remainingSamples.slice(toIndex)];
};
