import * as Sentry from '@sentry/react';
import { isStaging, isProduction } from './helpers';

const enableSentry = isStaging || isProduction;

export const initSentry = () =>
  Sentry.init({
    dsn: process.env.ADMIN_SENTRY_DSN,
    enabled: enableSentry,
    environment: process.env.CUSTOM_ENV,
    // buttons onerror message from aria-label tag
    // https://github.com/getsentry/sentry-javascript/issues/577#issuecomment-426929083
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const ariaLabel = hint?.event?.target?.ariaLabel;
        if (ariaLabel) {
          breadcrumb.message = ariaLabel; // eslint-disable-line no-param-reassign
        }
      }
      return breadcrumb;
    },
  });
