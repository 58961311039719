import React from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { client } from '../lib/apollo';
import { ApolloProvider } from '@apollo/client';
import { withErrorBoundary } from '../components/ErrorBoundary';
import FirebaseUserProvider from '../lib/userContext';
import CustomRouter from '../lib/customRouter';
import AppContext from '../lib/appContext';
import AppLayout from '../components/AppLayout';
import { DndProvider } from 'react-dnd';
import DndBackend from 'react-dnd-html5-backend';
import StagingHeader from '../components/StagingHeader';
import * as theme from '../theme';
import ROUTES from '../utils/routes';
import { initSentry } from '../utils/sentry';

import '../theme/reset.css';
import 'antd/dist/antd.css';
import '../theme/index.css';

initSentry();

const MyApp = ({ Component, pageProps, router }: AppProps) => (
  <>
    <Head>
      <title>SupertutorTV</title>
    </Head>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <FirebaseUserProvider>
          <DndProvider backend={DndBackend}>
            <CustomRouter>
              {router.route === ROUTES.signIn ? (
                <Component {...pageProps} />
              ) : (
                <AppContext>
                  <AppLayout>
                    {process.env.CUSTOM_ENV !== 'production' && <StagingHeader />}
                    <Component {...pageProps} />
                  </AppLayout>
                </AppContext>
              )}
            </CustomRouter>
          </DndProvider>
        </FirebaseUserProvider>
      </ApolloProvider>
    </ThemeProvider>
  </>
);

export default withErrorBoundary(MyApp);
