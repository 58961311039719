import styled, { css } from 'styled-components';
import { center } from '../../utils/mixins';
import { Props } from './Loader';

const getSizeCSS = (size: Props['size'] = 'default') =>
  ({
    default: `width: 100px;`,
    large: `width: 200px;`,
  }[size]);

export const Loader = styled.div<Props>`
  ${({ center: c, size }) => css`
    ${c === true && center}
    ${c === 'block' && 'margin: 0 auto;'}
    ${getSizeCSS(size)}
  `}
`;
