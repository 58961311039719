import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 25px;
  text-align: center;
`;

export const BulbWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const BulbImage = styled.img`
  width: 100%;
  max-width: 300px;
`;

export const BulbText = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: -7%;
    left: 2%;
    color: ${theme.colors.supernova};
    font-weight: ${theme.fontWeights.bold};
    font-size: 90px;
  `}
`;
